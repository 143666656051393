.table {
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
  background-color: transparent !important;
  @apply rtl:text-right;
}

.table tbody tr td,
.table tbody tr th {
  @apply bg-secondary-light-100 dark:bg-secondary-dark-100 border-0;
}

.table thead tr td,
.table thead tr th {
  @apply bg-transparent;
}

.table tbody tr th:nth-child(1),
.table tbody tr td:nth-child(1) {
  @apply ltr:rounded-l-xl rtl:rounded-r-xl;
}

.table tbody tr td:last-child {
  @apply ltr:rounded-r-xl rtl:rounded-l-xl;
}

.table-normal :where(th, td) {
  padding: 0.36rem 1.5rem !important;
}

.table tr {
  height: 59.5px;
}
